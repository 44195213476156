export function CardColor({ slug }: { slug?: string }) {
    return (
        <img
            src={`/assets/icons/${slug}.svg`}
            className="size-14 contrast-100 grayscale dark:invert"
            loading="lazy"
            alt={`Logo ${slug}`}
        />
    )
}
