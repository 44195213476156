import { CardColor } from '@/components/card-color'
import { HeroHighlight } from '@/components/highlight'
import { ArticlesData, TagsData } from '@/types/generated'
import { Badge, Card, Link } from 'ui'

// import { CardColor } from '@/components/card-color'

export function ArticleCard({ article }: { article: ArticlesData }) {
    return (
        <article className="flex min-h-52 flex-col space-y-3">
            <Link href={route('articles.show', [article])} className="flex h-full w-full items-center">
                <Card className="relative h-full flex-1 overflow-clip">
                    <HeroHighlight>
                        <Card.Header className="flex w-full items-center justify-center">
                            {/*<CardDescription className="text-sm">{article.teaser}</CardDescription>*/}
                            <CardColor
                                slug={
                                    Array.isArray(article.tags)
                                        ? article.tags[0]?.slug === undefined
                                            ? 'undefined'
                                            : article.tags[0]?.slug
                                        : 'undefined'
                                }
                            />
                        </Card.Header>
                    </HeroHighlight>
                </Card>
            </Link>
            <div className="flex flex-col gap-y-1.5 px-3">
                <Link href={route('articles.show', [article])}>
                    <div className="h-9 text-sm font-medium">{article.title}</div>
                </Link>
                <div className="z-10 flex items-center justify-between">
                    <time className="text-xs text-muted-fg">{article.published_at}</time>
                    {Array.isArray(article.tags) ? (
                        <div className="flex items-center gap-x-2">
                            {article.tags.map((tag: TagsData, i: number) => (
                                <Link key={'tag-' + tag.slug + '-' + i} href={route('tags.show', [tag])}>
                                    <Badge intent="secondary" shape="circle">
                                        {tag.name}
                                    </Badge>
                                </Link>
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </article>
    )
}
